<template>
 <div>
  <el-form-item label="File" v-if="filelist.length > 0">
    <el-select-v2
      v-model="selection"
      :options="filelist"
      placeholder="Please select"
      size="small"
      @change="update_data"
      value-key="value.id"
    />
  </el-form-item>
  <embed :src="blobUrl" :type="content_type" style="max-width: 99%;"  max-height="800px"/>
 </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
  const blobUrl = ref(null)
  const content_type = ref (null)
  const selection = ref(null) 
  const store = useStore()
  const filelist = computed(() => {
    return store.getters['api/filelist'].map(f => { return { label: f.docfile.split("/").at(-1), value: {url: f.docfile,id: f.id}} })
  });
  

  watch(filelist, async (currentValue, oldValue) => {
      if (currentValue == oldValue){
        return
      }
      blobUrl.value = null
      get_file();
    });

  const get_url = (id) =>{
    if (store.state.api.filelist.length > 0) {
      if (id) {
        return store.state.api.filelist.filter(item => {return item.id === id})[0].docfile
      } else{
        return store.state.api.filelist[0].docfile
      }
      
      // 
    } else {
      return null
    }
  }
  const update_data = () => {
    get_file()
  }

  const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

  const get_file = () => {
    if (filelist.value.length == 0) {
      return
    }

    const url = get_url(selection.value) 
    store.dispatch('api/filedownload', { url: url, display: true })
        .then((response) => {
          if (response != null) {
            content_type.value = response.type
            blobToBase64(response)
            .then((data) =>{
              blobUrl.value = data
            })
            
          }
        })
        .catch((err) => {
          console.log(err)
        })
  }
</script>

<style>
</style>
