<template>
  <div id="report_grid">
    <el-table
      :data="data"
      border
      header-row-class-name="grid_header"
      style="width: 100%"
      :row-class-name="tableRowClassName"
      size="mini"
    >

      <el-table-column
        class-name="index"
        fixed="left"
        type="index"
        width="40"
        :index="indexCounter"
      />

      <el-table-column fixed="left" width="28" class-name="row_actions">
        <template #default="scope">
          <el-dropdown trigger="click" >
                <el-button :icon="More" style="border: none; padding-left: 0px; padding-top: 9px;"></el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="row_function(scope)">Atvērt</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
            </el-dropdown>
        </template>
      </el-table-column>

      <el-table-column
        v-for="col in props.meta.c"
        :label="col.text || col.f"
        :key="col.f"
        :prop="col.f"
        :min-width="col.w"
        sortable
        class-name="row_data"
      >
      </el-table-column>
    </el-table>


    <el-pagination
      :pager-count="9"
      :page-size="perPage"
      :total="total_records"
      @current-change="current_page"
      layout="prev, pager, next"
      :small="true"
      :hide-on-single-page="true"
      background
      style="margin-left: 10px;width: auto; float: right; margin-top:5px;"
    ></el-pagination>
  </div>
</template>


<script>
import { More } from '@element-plus/icons'; // ArrowLeft, ArrowRight 
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { apiHelpers } from "../helpers";
import { uiHelpers } from "../ui_helpers";
export default {
  name: "ModelDetails",
  props: {
    meta: Object,
    vmodel: String
  },
  setup(props) {
    const { store } = apiHelpers(props);
    const { menus } = uiHelpers(props);
    const router = useRouter();
    const currentPage = ref(1)
    const perPage = ref(50)
    // const data = computed(() => store.state.api[props.vmodel][props.meta.f])
    const tablestart = computed(() => (currentPage.value - 1) * perPage.value)
    const total_records = computed(() => store.state.api[props.vmodel][props.meta.f].length)
    const data = computed(() => store.state.api[props.vmodel][props.meta.f].slice(tablestart.value, tablestart.value + perPage.value))
    const current_page = async (current) => {
      currentPage.value = current
    }
    const indexCounter = (index) => {
      return index + tablestart.value + 1
    }


    const tableRowClassName = ({row}) => {
      var res = ""
      if (row._m && row._m.class) {
        res = row._m.class
      }
      if (store.state.ui.widgets["report"]) {
        if (store.state.ui.widgets["report"].selected && JSON.stringify(row) == store.state.ui.widgets["report"].selected) {
          res += ' selected-row'
        } 
      }
      
      return res
    }


    const row_function = (scope) => {
      let routeData = router.resolve({name: 'popup', params: {model: scope.row.omod, key: scope.row.nr}});
      store.commit("ui/widget_row_select",{widget: "report", row: JSON.stringify(scope.row)})
      window.open(routeData.href,'reportpopup'+scope.row.nr, 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1100,height=700');
    }
    const controls = ref(false)
    const clearable = ref(false)
    const prefix_icon = ref(null)
    const min = ref(null)
    return {
      data,
      props,
      store,
      controls,
      clearable,
      prefix_icon,
      min,
      perPage,
      total_records,
      current_page,
      tablestart,
      indexCounter,
      menus,
      More,
      row_function,
      tableRowClassName
    };
  },
};
</script>

<style>
  #report_grid {
   .row_data {
        padding: 1px 0 !important;
    }

    .subtotal-row {
      font-weight: 700;
      background-color: rgb(248, 248, 248);
    }

    .warning-row {
      background-color: rgb(255, 200, 200);
    }

    .selected-row {
      font-weight: 800;
    }
    .row_actions{
      padding: 1px 0 !important;

      .cell{
        padding: 0 6px;
      }
    }

    .el-button {
      background-color: inherit;
    }
    
  }


</style>
