<template>
  <div>
    <el-form label-width="100px" size="mini">
      <div :id="get_id">
        <el-row v-for="(motherrow, motherrowindex) in v.fields" :key="motherrowindex">
          <el-col  v-for="(mothercol, motherindex) in motherrow" :key="motherindex" :xs="24" :sm="24" :md="24/motherrow.length" :lg="24/motherrow.length" :xl="24/motherrow.length">
            <el-row v-for="(row, index) in mothercol" :key="index">
              <el-col v-for="(col, index) in row" :key="index" :xs="24" :sm="Math.max(calcwidth(col.w, 12), 12)"
                
                :md="calcwidth(col.w, 8)" :lg="calcwidth(col.w, 6)" :xl="calcwidth(col.w, 4)">
                <Grid v-if="col.t == 'Grid'" :meta="col" :vmodel="view_type" />
                <DynamicGrid v-else-if="col.t == 'DynamicGrid'" :meta="col" :vmodel="view_type" />
                <Steps v-else-if="col.t == 'Steps'" :meta="col" :vmodel="view_type" />
                <PDFJsView v-else-if="col.t == 'PDFJsView'" />
                <PDFView v-else-if="col.t == 'PDFView'" />
                <ReportGrid v-else-if="col.t == 'ReportGrid'" :meta="col" :vmodel="view_type" />
                <Calendar v-else-if="col.t == 'Calendar'" :meta="col"/>
                <EmbedView v-else-if="col.t == 'EmbedView'" />
                <InputField v-else :meta="col" :vmodel="view_type" :type="props.type" />
                
                
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>


<script>
import Grid from "../components/Grid.vue";
import InputField from "../components/InputField.vue";
import ReportGrid from "../components/ReportGrid.vue";
import { uiHelpers } from "../ui_helpers";

import { computed } from 'vue';
import DynamicGrid from "../components/DynamicGrid.vue";
import Calendar from "./Calendar.vue";
import EmbedView from "./EmbedView.vue";
import PDFJsView from "./PDFJsView.vue";
import PDFView from "./PDFView.vue";
import Steps from "./Steps.vue";
export default {
  name: "ModelDetails",
  components: {
    InputField,
    Grid,
    ReportGrid,
    DynamicGrid,
    Steps,
    PDFView,
    PDFJsView,
    Calendar,
    EmbedView
  },
  props: {
    model: String,
    key: String,
    type: String,
    override_ui: Object
  },
  inheritAttrs: false,
  setup(props) {
    const { view, meta } = uiHelpers(props.model);
    const view_type = computed(() => props.type != 'SC' ? 'record_details' : 'schedule_details')
    const v = computed(() =>{
      if (props.override_ui){
        return props.override_ui.view
      } else {
        return view.value
      }
    })
    const calcwidth = (w, d) => {
      return typeof (w) !== 'undefined' && w !== null ? w : d
    }
    const get_id = computed(() => (props.type == 'D' | props.type == 'S' | props.type == 'R') ? 'recordheader' : 'functionheader')
    return {
      meta,
      props,
      view,
      calcwidth,
      get_id,
      v,
      view_type
    };
  },
};
</script>
<style scoped>
.el-row {
  margin-bottom: 5px !important;
}
</style>
<style>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-form-item {
  display: flex;
  margin-bottom: 3px !important;
}

#recordheader {
  height: calc(100vh - 126px);
  overflow: auto;
  padding-top: 15px;
  background-color: rgba(255, 255, 255, 0.6);
}

#functionheader {
  height: 50%;
  overflow: auto;
}

input:hover {
  border-color: var(--el-input-hover-border, var(--el-border-color-hover));
}

input {
  -webkit-appearance: none;
  background-color: var(--el-input-background-color, var(--el-color-white));
  background-image: none;
  border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
  border: var(--el-input-border, var(--el-border-base));
  box-sizing: border-box;
  color: var(--el-input-font-color, var(--el-text-color-regular));
  display: inline-block;
  outline: 0;
  padding: 0 15px;
  transition: var(--el-transition-border);
  width: 100%;
  height: 28px;
  line-height: 28px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.el-form-item__label{
  width: 130px;
}
</style>
