<template>
  <!-- <div> -->
    <div id="app_container">
      <Navbar v-if="this.$store.getters['auth/loggedIn'] & this.$route.name != 'popup'"></Navbar>
      <!-- <Navbar v-if="this.$store.getters['auth/loggedIn']"></Navbar> -->
      <router-view :key="refresh"></router-view>
      <LookupList  v-if="this.$store.getters['auth/loggedIn']"/>
      <Log  v-if="this.$store.getters['auth/loggedIn']"/>
    </div>
    
  <!-- </div> -->
</template>

<script>
import LookupList from "./components/LookupList";
import Navbar from "./components/Navbar.vue";
import Log from "./components/Log.vue";
import { ref, provide } from 'vue'
import { useStore } from "vuex";
import { messages } from './popups.js'
import i18n from './locales.js'
export default {
  name: "App",
  components: {
    Navbar,
    LookupList,
    Log
  },
  computed: {
    refresh: function () { 
      // console.log(this.$route.name)
      return this.$route.params.module + this.$route.params.model + "_" 
    }
  },
  setup() {
    const store = useStore();
    let currentrow = ref(null);
    let currentgrid = ref(null);
    let currentfield = ref(null);
    let lookupvisible = ref(false);
    let select_model = ref("");

    let dialogvisible = ref(false)
    let current_detail_store = ref(null);
    let current_dialog_view = ref(null);
    provide("currentrow", currentrow)
    provide("currentgrid", currentgrid)
    provide("currentfield", currentfield)
    provide("lookupvisible", lookupvisible)
    provide("select_model", select_model)
    provide("dialogvisible", dialogvisible)
    provide("current_detail_store", current_detail_store)
    provide("current_dialog_view", current_dialog_view)
    
    const { notify, showerorr } = messages()
    const get_ws_url = async () => {
      if (store.state.auth.ws_ticket == null){
          await store.dispatch('auth/update_ws_ticket')
      }
      if (store.state.auth.ws_ticket != null){
          return store.getters["auth/ws_address"]
      } else{
        return 234
      }
      
    }
 
    let ws_connection = ref()
    let ws_connencted = ref(false)
    let ws_reconnecting = ref(false)
    const ws_open = async () => {
      if(ws_connencted.value == true){
        return
      }
      const conn = new WebSocket(await get_ws_url());
      conn.onopen = function() {
        this.send("Hello")
        ws_connencted.value = true
        if(ws_reconnecting.value == true){
          notify('success', i18n.t('messages.con_restored'))
        }
      };

      conn.onmessage = function(event) {
        const res = JSON.parse(event.data)
        if (res['type'] == 'log_return'){
          store.commit('api/show_log',{data: res['message']})
        }
        else if (res['type'] == 'set_progress'){
          store.commit('api/set_progress',{data: res['message']})
        }
        else if (res['type'] == 'request_download'){
          // store.commit('api/show_log',{data: res['message']})

          store.dispatch("api/filedownload", { url: "/download/"+res['message']})
              .then(()=>{
              })
              .catch(err => {
                showerorr(err)
              })
          store.commit('api/show_log',{data: ["Done"]})
            

        }
        if (res['type'] == 'broadcast'){
          notify(i18n.t('messages.broadcast'), res['message'])
        }
        if (res['type'] == 'channel_name'){
          store.commit('auth/set_ws_channel',res['message'])
        }
        // console.log(res);
      };

      conn.onclose = async (event) => {
        
        if (!event.wasClean && ws_reconnecting.value == false && ws_connencted.value == true) {
          notify('error', i18n.t('messages.con_lost') + event.code)
          ws_connencted.value = false
          wait_ws_open()
        }else{
          if (ws_reconnecting.value == false && store.getters['auth/loggedIn']){
            await store.dispatch('auth/update_ws_ticket')
            wait_ws_open()
          }
          console.log(event)
          ws_connencted.value = false
        }
        
      };
      ws_connection.value = conn
    }
    const wait_ws_open = async () => {
      ws_reconnecting.value = true
      while (ws_connencted.value == false && store.getters['auth/loggedIn']){
        await new Promise((r) => setTimeout(r, 5000));
        ws_open()
        await new Promise((r) => setTimeout(r, 5000));
      }
      ws_reconnecting.value = false
    }
    if (store.getters['auth/loggedIn']){
      ws_open()
    }
      
    // const ws_connection = ws_open()

    const ws_send = (msg) => {
      ws_connection.value.send(msg)
    }
    const ws_close = (code) => {
      if (typeof ws_connection.value !== 'undefined'){
        ws_connection.value.close(code)
      }
        
    }
    provide("ws_connection", {ws_open,ws_send,ws_close})
    return {
    }
  },
  created() {
    this.axios.interceptors.request.use(
      
      async config => {
        if (!this.$store.getters['auth/loggedIn']  & !this.$store.state.auth.ignore_auth){
          throw new this.axios.Cancel();
        } else{
          await this.$store.dispatch("auth/wait_new_token",config.url) 
          config.headers.Authorization = this.$store.state.auth.accessToken
          config.headers["X-ws-ticket"]= this.$store.state.auth.ws_ticket
          config.headers["X-ws-channel"]= this.$store.state.auth.ws_channel
          return config;
        }
        
      },
      error => {
        Promise.reject(error)
    });
  

    this.axios.interceptors.response.use((response) => {
      return response
    }, async (error) => {
      if (error.constructor == this.axios.Cancel){
        return Promise.reject();
      }
      if (this.$store.state.auth.ignore_auth){
        return Promise.reject(error);
      }
      
      const originalRequest = error.config;
      if (error.response.status === 401) {
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          if (this.$store.state.auth.accessToken != null && originalRequest.headers.Authorization == this.$store.state.auth.accessToken){
              await this.$store.dispatch("auth/refreshToken")   
            originalRequest.headers.Authorization = this.$store.state.auth.accessToken;
          } else{
            originalRequest.headers.Authorization = this.$store.state.auth.accessToken
          }
          return this.axios(originalRequest);
      
          
        } else {
          this.$store.commit('auth/destroyToken')
          this.$router.push('/login')
        }
      }
      return Promise.reject(error);
    });

  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: "segoe ui", "helvetica", "verdana", "arial";
  background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4VkA83LUCDk5Bgm-iPOvMmlGpZYFP--LYVA&usqp=CAU');
}

#app {
  width: 100%;
  height: 100%;
}

#app_container {
  width: 100%;
  height: 100%;
}

.el-table--mini .el-table__cell{
  padding: 4px 0 !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #334762 !important;
}
</style>
