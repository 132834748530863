import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import VuePdf from 'vue3-pdfjs'
import App from './App.vue'
import i18n from './locales.js'
import { messages } from './popups.js'
import router from './routes.js'
import store from './store/store.js'
// import store from './store'


router.beforeEach((to, from, next) => {

    if (store.state.ui.type == 1 & ["C","M","D"].indexOf(store.state.api.status)>-1) {
        if (store.state.auth.accessToken !== null){
            const answer = window.confirm(
                i18n.t('messages.unsaved_leave')
            );
            if (!answer) {
                return Promise.reject()
            } else {
                store.state.api.status = ""
            }
        }
        
        store.commit("api/setState", {state: ""})
    }
    // console.log(store.getters['api/something_runing'])
    // console.log(store.state.api.something_runing)
    // if (store.state.api.something_runing == true){
    if (store.getters['api/something_runing']){

        const answer = window.confirm(
            i18n.t('messages.lose_context')
        );
        if (!answer) {
            return Promise.reject()
        }
    }
        

    if (to.name != 'login' && to.name != 'logout' && !store.getters['auth/loggedIn']) {
        next({ name: 'login', query: { redirect: to.fullPath } })
    } else {
        next()
    }


})
function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function check_session(){
    const { notify } = messages()
    if (store.getters['auth/loggedIn']){
        let t
        try {
            const jwtPayload = parseJwt(store.state.auth.refreshToken);
            t = jwtPayload.exp  - Date.now()/1000
        } catch(error){
            t = 99999999
        } finally {
            // console.log(t)
            if (t < 60*15 &&  t > 0) {
                if (t < 61){
                    notify('warning', i18n.t('messages.ses_end_in')+Math.round(t) + i18n.t('messages.ses_sec'))
                } else{
                    notify('warning', i18n.t('messages.ses_end_in')+Math.round(t/60) + i18n.t('messages.ses_min'))
                }
                
            }
            else if (t <= 0){
                notify('warning', i18n.t('messages.ses_ended'))
                store.dispatch("auth/userLogout")
            }

            if (t <= 0) 
                router.push('/login')
            else{
                if (t < 61){
                    setTimeout(check_session,t*1000)
                }else{
                    setTimeout(check_session,60000)
                }
            }
                
        }

    } else
        setTimeout(check_session,30000)
    
        
    }


setTimeout(check_session,0)

const app = createApp(App).use(store)
app.config.performance = true
app.use(VueAxios, axios)
app.use(router);
app.use(i18n);
app.use(VuePdf)
app.config.globalProperties.axios = axios
store.$axios = app.axios
store.$app = app;
if (store.state.auth.host == null){
    store.commit("auth/set_host",window.location.host)
}

axios.defaults.baseURL = store.getters["auth/api_address"]
axios.defaults.headers['Accept-Language'] = store.state.ui.locale || 'en'
axios.defaults.timeout = 30000  // 30 seconds timeout
axios.defaults.timeoutErrorMessage = 'Request timeout - please try again'
i18n.setLocale(store.state.ui.locale || 'en');

app.use(ElementPlus)
app.mount('#app');

